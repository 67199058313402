<template>
  <div>
    <el-row style="margin-top: 30px">
      <legend>支付申报</legend>
      <br>
      <el-col>
        <el-table
            :data="tableData"
            style="width: 100%"
            tooltip-effect="dark"
            :border="true"
            max-height="650"
            :highlight-current-row="true"
            :header-cell-style="{'background-color': '#F1F4FF' ,'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          >
          <el-table-column
              label="申报日期"
              prop="declarationDate"
          >
          </el-table-column>
          <el-table-column
              label="请款人"
              prop="declareName"
          >
          </el-table-column>
          <el-table-column
              label="申报描述"
              prop="content"
          >
          </el-table-column>
          <el-table-column
              label="报销收款方式"
              prop="spendPeopleName"
          >
          </el-table-column>
          <el-table-column
              label="总金额"
              prop="spendAmount"
          >
          </el-table-column>
          <el-table-column
              label="申报状态"
          >
            <template slot-scope="scope">
              {{scope.row.state === 0 ? "新建未提交" :scope.row.state === 1 ? "提交待审核" :scope.row.state === 2 ? "已审核待支付" :scope.row.state === 3 ? "已支付" :scope.row.state === 4 ? "已退回" : "申报已完成"}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="success"
                  @click="handlePay(scope.row)">支付</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            style="margin-top: 10px">
        </el-pagination>
      </el-col>
    </el-row>
    <declareEdit
        :declare="declare"
        :isOperation="isOperation"
        :declareEditState="payVisible"
        :is-payment="true"
        v-if="payVisible"
        @close="declareEditClose"
    ></declareEdit>
  </div>
</template>

<script>
import DeclareEdit from "@/pages/spend/declare/declare-edit";
export default {
  name: "declare-payment",
  components: {DeclareEdit},
  data() {
    return {
      searchForm: {

      },
      tableData: [],
      spendPeopleArray: [],
      total: 1,
      limit: 100,
      current: 1,
      id: '',
      declare: {},
      isOperation: false,
      payVisible: false,
      tenantCrop: localStorage.getItem('tenantCrop')
    }
  },
  created() {
    this.queryUnpaidSpendDeclare()
  },
  methods: {
    // 查询所有审核通过且未支付的申报
    queryUnpaidSpendDeclare() {
      this.$axios({
        method: 'GET',
        url: '/spendDeclare/spendDeclareList',
        params: {
          tenantCrop: this.tenantCrop,
          page: this.current,
          limit: this.limit,
          state: 2
        }
      }).then(response => {
        this.tableData = response.data.data.list
        this.total = response.data.data.total
        console.log(response.data.data)
      })
    },
    // 重置搜索条件
    reset() {

    },
    // 每页数变化
    handleSizeChange() {

    },
    // 当前页
    handleCurrentChange() {

    },
    // 支付申报
    handlePay(item) {
      this.declare = item
      this.payVisible = true
    },
    declareEditClose() {
      this.payVisible = false
      this.queryUnpaidSpendDeclare()
    }
  }
}
</script>

<style scoped>

</style>